import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import {Buffer} from "buffer";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [
            { "width": "100%", "targets": 1 },
            { className: 'dt-left', targets: 1 },
            ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: false,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const selectedStatus = ""; //$("#selectedStatus").val();
                const selectedBranch = $("#selectedBranch").val();
                await axios.post('/service_items_table', {status:selectedStatus, branch:selectedBranch, offset:data.start, limit:data.length, search:data.search.value, orderBy:"0", orderDir:"desc"}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const item = resData[vCount].device;
                    const sn = resData[vCount].sn;
                    const status = resData[vCount].status;
                    const client = resData[vCount].client;
                    const phone = resData[vCount].phone;
                    const addDate = getMyDate(resData[vCount].add_date);
                    const deviceStatusDesc = resData[vCount].device_status_desc;
                    const deviceIssue = resData[vCount].device_issue;
                    const receivedBy = resData[vCount].received_by;
                    const fixedBy = resData[vCount].fixed_by;
                    const deliveredBy = resData[vCount].delivered_by;
                    const fixedDate = getMyDate(resData[vCount].fixed_date);
                    const deliveredDate = getMyDate(resData[vCount].delivered_date);
                    const cost = resData[vCount].cost;
                    const price = resData[vCount].price;
                    const profit = resData[vCount].profit;
                    const acces = resData[vCount].acces;
                    const warranty = resData[vCount].warranty;
                    const note = resData[vCount].note;
                    const branch = resData[vCount].branch;
                    const result = resData[vCount].result;

                    var htmlStatus = "";
                    var htmlResult = "";
                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    if(self.state.user.access.includes("|74|")) {
                        buttons += "<a class='myAStyle' id='delete_service_item' itemId='"+id+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|75|")) {
                        buttons += "<a class='myAStyle' id='update_service_item' itemId='"+id+"' item='"+item+"' sn='"+sn+"' client='"+client+"' \
                                    phone='"+phone+"' desc='"+deviceStatusDesc+"' issue='"+deviceIssue+"' cost='"+cost+"' price='"+price+"' acces='"+acces+"' \
                                    note='"+note+"' staff='"+receivedBy+"'>Update Data</a>";
                    }
                    if(self.state.user.access.includes("|76|")) {
                        buttons += "<a class='myAStyle' id='update_service_status' itemId='"+id+"' item='"+item+"' sn='"+sn+"' client='"+client+"' \
                                    phone='"+phone+"' desc='"+deviceStatusDesc+"' issue='"+deviceIssue+"' cost='"+cost+"' price='"+price+"' acces='"+acces+"' \
                                    note='"+note+"' staff='"+receivedBy+"' status='"+status+"'>Update Service Status</a>";
                    }
                    buttons += "<a class='myAStyle' id='print_eng_receipt' itemId='"+id+"'>Print English Receipt</a>";
                    buttons += "<a class='myAStyle' id='print_ar_receipt' itemId='"+id+"'>Print Arabic Receipt</a>";
                    buttons += "</div></div>";
                    
                                                // <option value="Pending">Pending</option>
                                                // <option value="Fixing">Fixing</option>
                                                // <option value="Fixed">Fixed</option>
                                                // <option value="Failed">Failed</option>
                                                // <option value="Undamaged">Undamaged</option>
                                                // <option value="Rejected">Rejected</option>
                                                // <option value="Delivered">Delivered</option>
                    
                    if(status === "Pending") {
                        htmlStatus = '<p style="color: blue;">Pending</p>';
                    }
                    if(status === "Fixing") {
                        htmlStatus = '<p style="color: blue;">Fixing</p>';
                    }
                    if(status === "Fixed") {
                        htmlStatus = '<p style="color: lightgreen;">Fixed</p>';
                    }
                    if(status === "Failed") {
                        htmlStatus = '<p style="color: red;">Failed</p>';
                    }
                    if(status === "Undamaged") {
                        htmlStatus = '<p style="color: lightgreen;">Undamaged</p>';
                    }
                    if(status === "Rejected") {
                        htmlStatus = '<p style="color: red;">Rejected</p>';
                    }
                    if(status === "Delivered") {
                        htmlStatus = '<p style="color: lightgreen;">Delivered</p>';
                    }
                    if(status === "Delivering") {
                        htmlStatus = '<p style="color: lightgreen;">Delivering</p>';
                    }
                    if(status === "Sending") {
                        htmlStatus = '<p style="color: lightgreen;">Sending</p>';
                    }
                    
                    if(result === "Fixed") {
                        htmlResult = '<p style="color: lightgreen;">Fixed</p>';
                    }
                    if(result === "Failed") {
                        htmlResult = '<p style="color: red;">Failed</p>';
                    }
                    if(result === "Undamaged") {
                        htmlResult = '<p style="color: blue;">Undamaged</p>';
                    }
                    if(result === "Rejected") {
                        htmlResult = '<p style="color: red;">Rejected</p>';
                    }

                    out.push([id, item, sn, htmlStatus, client, phone, deviceStatusDesc, deviceIssue, receivedBy+" / "+addDate, fixedBy+" / "+fixedDate, deliveredBy+" / "+deliveredDate, price, acces, warranty, note, branch, htmlResult, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#print_eng_receipt");
        $(document).on("click", "#print_eng_receipt", function() {
            const itemId = $(this).attr("itemId");
            window.open("/print-eng-service-receipt/"+Buffer.from(itemId, "utf8").toString("base64"), "_blank");
        });
        $(document).off("click", "#print_ar_receipt");
        $(document).on("click", "#print_ar_receipt", function() {
            const itemId = $(this).attr("itemId");
            window.open("/print-ar-service-receipt/"+Buffer.from(itemId, "utf8").toString("base64"), "_blank");
        });
        $(document).off("click", "#update_service_item");
        $(document).on("click", "#update_service_item", function() {
            const itemId = $(this).attr("itemId");
            const device = $(this).attr("item");
            const sn = $(this).attr("sn");
            const client = $(this).attr("client");
            const phone = $(this).attr("phone");
            const desc = $(this).attr("desc");
            const issue = $(this).attr("issue");
            const cost = $(this).attr("cost");
            const price = $(this).attr("price");
            const acces = $(this).attr("acces");
            const note = $(this).attr("note");
            const staff = $(this).attr("staff");
            
            $("#inDeviceName2").val(device);
            $("#inDeviceSN2").val(sn);
            $("#inDeviceDesc2").val(desc);
            $("#inDeviceIssue2").val(issue);
            $("#inClientName2").val(client);
            $("#inClientPhone2").val(phone);
            $("#inStaffReceived2").val(staff);
            $("#inReceivedAccess2").val(acces);
            $("#inNote2").val(note);
            $("#inCost2").val(cost);
            $("#inPrice2").val(price);
            self.setState({adminIdToDel:itemId});
            window.$("#updateItemModal").modal("show");
        });
        $(document).off("click", "#update_service_status");
        $(document).on("click", "#update_service_status", function() {
            const itemId = $(this).attr("itemId");
            const device = $(this).attr("item");
            const sn = $(this).attr("sn");
            const cost = $(this).attr("cost");
            const price = $(this).attr("price");
            const status = $(this).attr("status");
            
            $("#inServiceStatus").val(status);
            $("#inCost3").val(cost);
            $("#inPrice3").val(price);
            self.setState({adminIdToDel:itemId, adminToDel:device+" - "+sn+" - "+status});
            window.$("#updateServiceStatus").modal("show");
        });
        $(document).off("click", "#delete_service_item");
        $(document).on("click", "#delete_service_item", function() {
            const itemId = $(this).attr("itemId");
            self.setState({adminIdToDel:itemId});
            
            window.$("#confirmDeleteItemModal").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|72|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#service_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();
            
            if(ok.data.access.includes("|73|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            window.$("#addItemModal").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
        })
    }
    
    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }
    
    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({clientsList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({clientsList:data});
            }
        })
        window.toastr.remove();
    }
    
    addServiceItem = () => {
        const deviceName = $("#inDeviceName").val();
        const sn = $("#inDeviceSN").val();
        const desc = $("#inDeviceDesc").val();
        const issue = $("#inDeviceIssue").val();
        const client = $("#inClientName").val();
        const phone = $("#inClientPhone").val();
        const staff = $("#inStaffReceived").val();
        const acces = $("#inReceivedAccess").val();
        const note = $("#inNote").val();
        const cost = $("#inCost").val();
        const price = $("#inPrice").val();
        const branch = this.state.user.branch ? this.state.user.branch : $("#inBranches").val();

        if(!deviceName) {
            window.toastr["error"]("Invalid Device Name");
            return;
        }
        if(!sn) {
            window.toastr["error"]("Invalid SN");
            return;
        }
        if(!desc) {
            window.toastr["error"]("Invalid Device Status Description");
            return;
        }
        if(!issue) {
            window.toastr["error"]("Invalid Device Issue");
            return;
        }
        if(!client) {
            window.toastr["error"]("Invalid Client Name");
            return;
        }
        if(!phone) {
            window.toastr["error"]("Invalid Client Phone");
            return;
        }
        if(!staff) {
            window.toastr["error"]("Invalid Staff Name");
            return;
        }
        
        axios.post("/add_service_item", {branch:branch, cost:cost, price:price, acces:acces, note:note, name:deviceName, sn:sn, desc:desc, issue:issue, client:client, phone:phone, staff:staff}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#addItemModal").modal("hide");
            }
        })
    }
    
    doPermDeleteItem = () => {
        const id = this.state.adminIdToDel;
        axios.post("/delete_service_item", {id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#confirmDeleteItemModal").modal("hide");
            }
        })
    }
    
    updateServiceItem = () => {
        const id = this.state.adminIdToDel;
        const deviceName = $("#inDeviceName2").val();
        const sn = $("#inDeviceSN2").val();
        const desc = $("#inDeviceDesc2").val();
        const issue = $("#inDeviceIssue2").val();
        const client = $("#inClientName2").val();
        const phone = $("#inClientPhone2").val();
        const staff = $("#inStaffReceived2").val();
        const acces = $("#inReceivedAccess2").val();
        const note = $("#inNote2").val();
        const cost = $("#inCost2").val();
        const price = $("#inPrice2").val();

        if(!deviceName) {
            window.toastr["error"]("Invalid Device Name");
            return;
        }
        if(!sn) {
            window.toastr["error"]("Invalid SN");
            return;
        }
        if(!desc) {
            window.toastr["error"]("Invalid Device Status Description");
            return;
        }
        if(!issue) {
            window.toastr["error"]("Invalid Device Issue");
            return;
        }
        if(!client) {
            window.toastr["error"]("Invalid Client Name");
            return;
        }
        if(!phone) {
            window.toastr["error"]("Invalid Client Phone");
            return;
        }
        if(!staff) {
            window.toastr["error"]("Invalid Staff Name");
            return;
        }
        
        axios.post("/update_service_item", {id:id, cost:cost, price:price, acces:acces, note:note, name:deviceName, sn:sn, desc:desc, issue:issue, client:client, phone:phone, staff:staff}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#updateItemModal").modal("hide");
            }
        })
    }
    
    updateServiceStatusItem = () => {
        const id = this.state.adminIdToDel;
        const status = $("#inServiceStatus").val();
        const staff = $("#inDeliveredBy3").val();
        const cost = $("#inCost3").val();
        const price = $("#inPrice3").val();
        const fixedBy = $("#inFixedBy3").val();
        const warranty = $("#inWarranty3").val();
        
        if(!status) {
            window.toastr["error"]("Invalid Status Value");
            return;
        }
        if(status === "Delivered") {
            if(!staff) {
                window.toastr["error"]("Invalid Delivered-By Staff Name");
                return;
            }
        }
        if(status === "Fixed" || status === "Fixing" || status === "Delivered") {
            if(!cost) {
                window.toastr["error"]("Invalid Cost Value");
                return;
            }
            if(!price) {
                window.toastr["error"]("Invalid Price Value");
                return;
            }
        }
        if(status === "Fixed" || status === "Undamaged" || status === "Failed") {
            if(!fixedBy) {
                window.toastr["error"]("Invalid Fixed/Checked-By Value");
                return;
            }
        }
        if(status === "Fixed") {
            if(!warranty) {
                window.toastr["error"]("Invalid Warranty Value");
                return;
            }
        }
        axios.post("/update_service_item_status", {warranty:warranty, fixed_by:fixedBy, id:id, status:status, staff:staff, cost:cost, price:price}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#updateServiceStatus").modal("hide");
            }
        })
    }
    
    render() {
        return(
            <>
            
            <div class="modal fade" id="confirmDeleteItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Confirm Delete Service Item
                        </div>
                        <div class="modal-body">
                            Are you sure ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.doPermDeleteItem();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#confirmDeleteItemModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="updateServiceStatus" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update Service Status for Item ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Status:</th>
                                        <td>
                                            <select id="inServiceStatus" className="myInputStyle" onChange={() => {
                                                $("#deliveredBySection").hide();
                                                $("#FixedBySection").hide();
                                                $("#warrantySection").hide();
                                                if($("#inServiceStatus").val() === "Delivered") {
                                                    $("#deliveredBySection").show();
                                                }
                                                if($("#inServiceStatus").val() === "Fixed" || $("#inServiceStatus").val() === "Undamaged" || $("#inServiceStatus").val() === "Failed") {
                                                    $("#FixedBySection").show();
                                                }
                                                if($("#inServiceStatus").val() === "Fixed") {
                                                    $("#warrantySection").show();
                                                }
                                            }}>
                                                <option value="Pending">Pending</option>
                                                <option value="Fixing">Fixing</option>
                                                <option value="Fixed">Fixed</option>
                                                <option value="Failed">Failed</option>
                                                <option value="Undamaged">Undamaged</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Delivering">Delivering</option>
                                                <option value="Sending">Sending</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr id="deliveredBySection" style={{display:"none"}}>
                                        <th>Delivered By:</th>
                                        <td><input class="myInputStyle" id="inDeliveredBy3" placeholder="Staff Who give device to client" /></td>
                                    </tr>
                                    <tr id="FixedBySection" style={{display:"none"}}>
                                        <th>Fixed/Checked By:</th>
                                        <td><input class="myInputStyle" id="inFixedBy3" placeholder="Staff Who Fixed/Checked Device" /></td>
                                    </tr>
                                    <tr id="warrantySection" style={{display:"none"}}>
                                        <th>Warranty:</th>
                                        <td><input class="myInputStyle" id="inWarranty3" placeholder="Warranty Days after Fix" /></td>
                                    </tr>
                                    <tr>
                                        <th>Cost:</th>
                                        <td><input class="myInputStyle" type="number" id="inCost3" placeholder="How much we will pay to fix device (Leave empty if unknown)" /></td>
                                    </tr>
                                    <tr>
                                        <th>Price:</th>
                                        <td><input class="myInputStyle" type="number" id="inPrice3" placeholder="How much customer will pay to us (Leave empty if unknown)" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addServiceDeviceItem' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateServiceStatusItem();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="updateItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update Service Item [{this.state.adminIdToDel}]
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th style={{width: "25%"}}>Device Name:</th>
                                        <td><input class="myInputStyle" id="inDeviceName2" placeholder="Samsung A05" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Serial / IMEI:</th>
                                        <td><input class="myInputStyle" id="inDeviceSN2" placeholder="354353*****" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Status Description:</th>
                                        <td><input class="myInputStyle" id="inDeviceDesc2" placeholder="Device status when received (Other main device Issue)" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Issue:</th>
                                        <td><input class="myInputStyle" id="inDeviceIssue2" placeholder="Main Device issue" /></td>
                                    </tr>
                                    <tr>
                                        <th>Client Name:</th>
                                        <td><input class="myInputStyle" id="inClientName2" placeholder="Frank Euro Mark" /></td>
                                    </tr>
                                    <tr>
                                        <th>Client Phone:</th>
                                        <td><input class="myInputStyle" id="inClientPhone2" type="number" placeholder="Phone number of client" /></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Received:</th>
                                        <td><input class="myInputStyle" id="inStaffReceived2" placeholder="Name of Staff received the device" /></td>
                                    </tr>
                                    <tr>
                                        <th>Received Accessories:</th>
                                        <td><input class="myInputStyle" id="inReceivedAccess2" placeholder="Like: Charger, Box..." /></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="inNote2" placeholder="" /></td>
                                    </tr>
                                    <tr>
                                        <th>Cost:</th>
                                        <td><input class="myInputStyle" type="number" id="inCost2" placeholder="How much we will pay to fix device (Leave empty if unknown)" /></td>
                                    </tr>
                                    <tr>
                                        <th>Price:</th>
                                        <td><input class="myInputStyle" type="number" id="inPrice2" placeholder="How much customer will pay to us (Leave empty if unknown)" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addServiceDeviceItem' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateServiceItem();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="addItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th style={{width: "25%"}}>Device Name:</th>
                                        <td><input class="myInputStyle" id="inDeviceName" placeholder="Samsung A05" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Serial / IMEI:</th>
                                        <td><input class="myInputStyle" id="inDeviceSN" placeholder="354353*****" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Status Description:</th>
                                        <td><input class="myInputStyle" id="inDeviceDesc" placeholder="Device status when received (Other main device Issue)" /></td>
                                    </tr>
                                    <tr>
                                        <th>Device Issue:</th>
                                        <td><input class="myInputStyle" id="inDeviceIssue" placeholder="Main Device issue" /></td>
                                    </tr>
                                    <tr>
                                        <th>Client Name:</th>
                                        <td><input class="myInputStyle" id="inClientName" placeholder="Frank Euro Mark" /></td>
                                    </tr>
                                    <tr>
                                        <th>Client Phone:</th>
                                        <td><input class="myInputStyle" id="inClientPhone" type="number" placeholder="Phone number of client" /></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Received:</th>
                                        <td><input class="myInputStyle" id="inStaffReceived" placeholder="Name of Staff received the device" /></td>
                                    </tr>
                                    <tr>
                                        <th>Received Accessories:</th>
                                        <td><input class="myInputStyle" id="inReceivedAccess" placeholder="Like: Charger, Box..." /></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="inNote" placeholder="" /></td>
                                    </tr>
                                    <tr>
                                        <th>Cost:</th>
                                        <td><input class="myInputStyle" type="number" id="inCost" placeholder="How much we will pay to fix device (Leave empty if unknown)" /></td>
                                    </tr>
                                    <tr>
                                        <th>Price:</th>
                                        <td><input class="myInputStyle" type="number" id="inPrice" placeholder="How much customer will pay to us (Leave empty if unknown)" /></td>
                                    </tr>
                                    {!this.state.user.branch && <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranches" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addServiceDeviceItem' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addServiceItem();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Service Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                
                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        {/*<tr>
                            <th>Status:</th>
                            <td>
                                <select id="selectedStatus" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    <option value="sold">Sold</option>
                                    <option value="ready">Ready</option>
                                </select>
                            </td>
                        </tr>*/}
                        <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Item</th>
                            <th>Serial</th>
                            <th>Status</th>
                            <th>Client</th>
                            <th>Phone</th>
                            <th>Status Description</th>
                            <th>Item Issue</th>
                            <th>Received By/Date</th>
                            <th>Fixed By/Date</th>
                            <th>Delivered By/Date</th>
                            <th>Price</th>
                            <th>Received Acces.</th>
                            <th>Warranty</th>
                            <th>Note</th>
                            <th>Branch</th>
                            <th>Result</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
