import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        adminTypeToDel: "",
        numOFFDays: "0",
        branchesList: ""
    }

    async setupSuppliersHistory() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table2').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = [];
                const selectedEmployee = $("#boxForSupplierId").val();
                const selectedDate = $("#selectedDate").val();
                // const selectedStaff = $("#boxForSupplierId").val();
                await axios.post('/staff_login_table', {selectedDate:selectedDate, selectedEmployee:selectedEmployee, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const staffId = resData[vCount].staff_id;
                    const staffName = resData[vCount].staff_name;
                    const loginDate = getMyDate(resData[vCount].login_date);
                    const status = resData[vCount].status;
                    const logoutDate = getMyDate(resData[vCount].logout_date);
                    const hoursPassed = resData[vCount].hours_passed;
                    const salary = resData[vCount].salary;
                    const workingHours = resData[vCount].working_hours;
                    const branch = resData[vCount].branch;
                    const offDays = resData[vCount].off_days;
                    const earns = resData[vCount].earns;
                    const loginNote = resData[vCount].login_note;
                    const logoutNote = resData[vCount].logout_note;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    // if(self.state.user.access.includes("|4|")) {
                    //     buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' itemType='"+type+"' style='color: red !important;'>Delete</a>";
                    // }
                    // if(self.state.user.access.includes("|5|")) {
                    //     buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemAccess='"+access+"' itemStatus='"+status+"' itemType='"+type+"' \
                    //                 itemSalary='"+salary+"' itemWorkHoursNum='"+workHoursNum+"' itemWorkHoursRange='"+workHoursRange+"' itemOffDaysName='"+offDaysName+"' itemBranch='"+branch+"'>Update</a>";
                    // }
                    // if(self.state.user.access.includes("|2|")) {
                    //     buttons += "<a class='myAStyle' id='view_supplier_logs' itemId='"+id+"' itemName='"+name+"'>History</a>";
                    // }
                    if(self.state.user.access.includes("|46|") && status === "Logged In") {
                        buttons += "<a class='myAStyle' id='logout_staff' itemId='"+id+"' itemName='"+staffName+"' style='color: red !important;'>Logout</a>";
                    }
                    if(self.state.user.access.includes("|60|") && status === "Logged Out") {
                        buttons += "<a class='myAStyle' id='update_staff_dates' itemId='"+id+"' itemName='"+staffName+"' loginDate='"+loginDate+"' hoursPassed='"+hoursPassed+"' \
                                                        salary='"+salary+"' workingHours='"+workingHours+"' logoutDate='"+logoutDate+"'>Update Dates</a>";
                    }
                    buttons += "</div></div>";
                    
                    out.push([id, staffId, staffName, loginDate, status, logoutDate, hoursPassed, salary, workingHours, branch, offDays, earns, loginNote, logoutNote, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
                self.setupEmployeeData();
            }
        });
        $(document).off("click", "#update_staff_dates");
        $(document).on("click", "#update_staff_dates", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const loginDate = $(this).attr("loginDate");
            const hoursPassed = $(this).attr("hoursPassed");
            const salary = $(this).attr("salary");
            const workingHours = $(this).attr("workingHours");
            const logoutDate = $(this).attr("logoutDate");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            
            $("#inStaffLoginDate").val(loginDate);
            $("#inStaffLogoutDate").val(logoutDate);
            $("#inStaffWorkingHours").html(workingHours);
            $("#inStaffPassedHours").html(hoursPassed);
            
            window.$("#updateDatesModal").off("shown.bs.modal").off("hidden.bs.modal");
            window.$("#updateDatesModal").on("shown.bs.modal", function () {
                $("#suppliersLogsHistory").css({zIndex: "1000"});
            }).on("hidden.bs.modal", function () {
                $("#suppliersLogsHistory").css({zIndex: "1050", "overflow-y": "auto"});
            });
            
            window.$("#updateDatesModal").modal("show");
        });
        $(document).off("click", "#logout_staff");
        $(document).on("click", "#logout_staff", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            window.$("#logoutStaffModal").modal("show");
        });
    }

    setupDatatable() {
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        const self = this;
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const selectedType = $("#selectedType").val();
                const selectedBranch = $("#selectedBranch").val();
                await axios.post('/admins_table', {branch:selectedBranch, type:selectedType, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const name = resData[vCount].username;
                    const access = resData[vCount].access;
                    const status = resData[vCount].status;
                    const type = resData[vCount].type;
                    const salary = resData[vCount].salary;
                    const workHoursNum = resData[vCount].work_hours_num;
                    const workHoursRange = resData[vCount].work_hours_range;
                    const offDaysNum = resData[vCount].off_days_num;
                    const offDaysName = resData[vCount].off_days_name;
                    const branch = resData[vCount].branch;
                    const balance = resData[vCount].balance;
                    const linkedClient = resData[vCount].linked_client;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    if(self.state.user.access.includes("|4|") && name !== self.state.user.username) {
                        buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' itemType='"+type+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|5|") && name !== self.state.user.username) {
                        buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemAccess='"+access+"' itemStatus='"+status+"' itemType='"+type+"' linkedClient='"+linkedClient+"' \
                                    itemSalary='"+salary+"' itemOffDaysNum='"+offDaysNum+"' itemWorkHoursNum='"+workHoursNum+"' itemWorkHoursRange='"+workHoursRange+"' itemOffDaysName='"+offDaysName+"' itemBranch='"+branch+"'>Update</a>";
                    }
                    if(self.state.user.access.includes("|2|")) {
                        buttons += "<a class='myAStyle' id='view_supplier_logs' itemId='"+id+"' itemName='"+name+"'>History</a>";
                    }
                    if(self.state.user.access.includes("|53|") && name !== self.state.user.username) {
                        buttons += "<a class='myAStyle' id='login_as_another_user' itemId='"+id+"' name='"+name+"'>ReLogin As ("+name+")</a>";
                    }
                    // if(self.state.user.access.includes("|54|")) {
                    //     buttons += "<a class='myAStyle' id='update_balance' itemId='"+id+"' itemName='"+name+"' itemBalance='"+balance+"'>Update Balance</a>";
                    // }
                    if(self.state.user.access.includes("|45|")) {
                        buttons += "<a class='myAStyle' id='view_login_system' itemName='"+name+"'>View Wroking Time Login History</a>";
                    }
                    buttons += "</div></div>";
                    
                    out.push([id, name, type, status, salary, workHoursNum, workHoursRange, offDaysNum, offDaysName, branch, linkedClient, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#view_login_system");
        $(document).on("click", "#view_login_system", function() {
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName});
            $("#boxForSupplierId").val(itemName);
            window.$("#suppliersLogsHistory").modal("show");
            self.setupSuppliersHistory();
        });
        // $(document).off("click", "#update_balance");
        // $(document).on("click", "#update_balance", function() {
        //     const itemId = $(this).attr("itemId");
        //     const itemName = $(this).attr("itemName");
        //     const itemBalance = $(this).attr("itemBalance");
        //     self.setState({adminToDel:itemName, adminIdToDel:itemId});
        //     $("#setStaffName").html(itemName);
        //     $("#setStaffBalance").html(itemBalance);
        //     window.$("#updateBalanceModal").modal("show");
        // });
        $(document).off("click", "#login_as_another_user");
        $(document).on("click", "#login_as_another_user", function() {
            const itemId = $(this).attr("itemId");
            const name = $(this).attr("name");
            axios.post("/login_as_user", {id:itemId, name:name}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.open("/panel", "_self");
                }
            })
        });
        $(document).off("click", "#view_supplier_logs");
        $(document).on("click", "#view_supplier_logs", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, itemIdLogsHistory:itemId});
            $("#boxForSupplierId").val(itemId);
            window.$("#suppliersLogsHistory").modal("show");
            self.setupSuppliersHistory();
        });
        $(document).off("click", "#delete_admin");
        $(document).on("click", "#delete_admin", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemType = $(this).attr("itemType");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, adminTypeToDel:itemType});
            window.$("#deleteAdminModal").modal("show");
        });

        $(document).off("click", "#edit_admin");
        $(document).on("click", "#edit_admin", async function() {
            const itemSalary = $(this).attr("itemSalary");
            const itemWorkHoursNum = $(this).attr("itemWorkHoursNum");
            const itemWorkHoursRange = $(this).attr("itemWorkHoursRange");
            const itemOffDaysName = $(this).attr("itemOffDaysName");
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemAccess = $(this).attr("itemAccess");
            const itemStatus = $(this).attr("itemStatus");
            const itemType = $(this).attr("itemType");
            const itemBranch = $(this).attr("itemBranch");
            const itemOffDaysNum = $(this).attr("itemOffDaysNum");
            const linkedClient = $(this).attr("linkedClient");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#emailSetEdit").html(itemName);
            $("#adminUpdateStatus").val(itemStatus);
            $("#adminType").val(itemType);
            const accessData = document.querySelectorAll(".queryAccess");
            for(var i = 0; i < accessData.length; ++i) {
                if(itemAccess.includes(accessData[i].id)) {
                    accessData[i].checked = true;
                } else {
                    accessData[i].checked = false;
                }
            }
            $("#emailSetView").show();
            $("#emailInputView").hide();
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();
            $("#statusView").show();
            $("#emailEditView").show()
            $("#passwordInput").attr("placeholder", "Leave empty to avoid change...");
            await self.setupBranches();
            if($("#adminType").val() === "employee") {
                $("#salaryIn").val(itemSalary);
                $("#workHoursFromToIn").val(itemWorkHoursRange);
                const offDaysName = document.querySelectorAll(".queryAccessDays");
                for(var i = 0; i < offDaysName.length; ++i) {
                    if(itemOffDaysName.includes(offDaysName[i].id)) {
                        offDaysName[i].checked = true;
                    } else {
                        offDaysName[i].checked = false;
                    }
                }
                $(".employeeOptions").show();
                $("#branchIn").val(itemBranch);
                $("#offDaysIn").val(itemOffDaysNum);
                self.processDays();
                self.processTimeSum();
            } else if($("#adminType").val() === "admin") {
                $("#salaryIn").val("");
                $("#workHoursFromToIn").val("");
                $("#workHoursIn").val("");
                $("#offDaysIn").val("");
                $("#selectDaysBtn").text("");
                const offDaysName = document.querySelectorAll(".queryAccessDays");
                for(var i = 0; i < offDaysName.length; ++i) {
                    offDaysName[i].checked = false;
                }
                $(".employeeOptions").hide();
            }
            // window.toastr["info"]("Loading Data...");
            // await self.setupClients();
            // window.toastr.remove();
            $("#linkedClientVal").val(linkedClient);
            window.$("#addAdminModal").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|2|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#admins_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();

            if(ok.data.access.includes("|3|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={async() => {
                            $("#emailSetView").hide();
                            $("#emailInputView").show();
                            $("#addAdminBtn").show();
                            $("#editAdminBtn").hide();
                            $("#statusView").hide();
                            $("#emailEditView").hide();
                            $("#passwordInput").attr("placeholder", "");
                            if($("#adminType").val() === "employee") {
                                $(".employeeOptions").show();
                            } else if($("#adminType").val() === "admin") {
                                $(".employeeOptions").hide();
                            }
                            await this.setupBranches();
                            $("#offDaysIn").val(0);
                            const offDaysName = document.querySelectorAll(".queryAccessDays");
                            for(var i = 0; i < offDaysName.length; ++i) {
                                offDaysName[i].checked = false;
                            }
                            this.processDays();
                            // window.toastr["info"]("Loading Data...");
                            // await this.setupClients();
                            // window.toastr.remove();
                            $("#linkedClientVal").val("");
                            window.$("#addAdminModal").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
            this.setState({perms:[
                <>
                {ok.data.access && ok.data.access.includes("|1|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|1|" />
                        <label class="queryAccess2 myLabelForCheckbox" for="|1|">Login Panel</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|2|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|2|" onChange={() => {
                            if(!document.getElementById("|2|").checked) {
                                if(document.getElementById("|3|")) {
                                    document.getElementById("|3|").checked = false;
                                }
                                if(document.getElementById("|4|")) {
                                    document.getElementById("|4|").checked = false;
                                }
                                if(document.getElementById("|5|")) {
                                    document.getElementById("|5|").checked = false;
                                }
                                if(document.getElementById("|53|")) {
                                    document.getElementById("|53|").checked = false;
                                }
                                if(document.getElementById("|54|")) {
                                    document.getElementById("|54|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|2|">View Admins</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|3|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|3|" onChange={() => {
                            if(document.getElementById("|3|").checked) {
                                document.getElementById("|2|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|3|">Add Admins</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|4|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|4|" onChange={() => {
                            if(document.getElementById("|4|").checked) {
                                document.getElementById("|2|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|4|">Remove Admins</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|5|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|5|" onChange={() => {
                            if(document.getElementById("|5|").checked) {
                                document.getElementById("|2|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|5|">Edit Admins</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|53|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|53|" onChange={() => {
                            if(document.getElementById("|53|").checked) {
                                document.getElementById("|2|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|53|">Login as Another User</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|54|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|54|" onChange={() => {
                            if(document.getElementById("|54|").checked) {
                                document.getElementById("|2|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|54|">Update Staff Balance</label>
                    </div>
                }


                {ok.data.access && ok.data.access.includes("|6|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|6|" onChange={() => {
                            if(!document.getElementById("|6|").checked) {
                                if(document.getElementById("|7|")) {
                                    document.getElementById("|7|").checked = false;
                                }
                                if(document.getElementById("|8|")) {
                                    document.getElementById("|8|").checked = false;
                                }
                                if(document.getElementById("|9|")) {
                                    document.getElementById("|9|").checked = false;
                                }
                                if(document.getElementById("|10|")) {
                                    document.getElementById("|10|").checked = false;
                                }
                                if(document.getElementById("|11|")) {
                                    document.getElementById("|11|").checked = false;
                                }
                                if(document.getElementById("|16|")) {
                                    document.getElementById("|16|").checked = false;
                                }
                                if(document.getElementById("|21|")) {
                                    document.getElementById("|21|").checked = false;
                                }
                                if(document.getElementById("|59|")) {
                                    document.getElementById("|59|").checked = false;
                                }
                                if(document.getElementById("|61|")) {
                                    document.getElementById("|61|").checked = false;
                                }
                                if(document.getElementById("|65|")) {
                                    document.getElementById("|65|").checked = false;
                                }
                                if(document.getElementById("|66|")) {
                                    document.getElementById("|66|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|6|">View Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|7|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|7|" onChange={() => {
                            if(document.getElementById("|7|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|7|">Add Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|8|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|8|" onChange={() => {
                            if(document.getElementById("|8|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|8|">Trash Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|9|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|9|" onChange={() => {
                            if(document.getElementById("|9|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|9|">Edit Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|10|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|10|" onChange={() => {
                            if(document.getElementById("|10|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|10|">View Cost Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|11|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|11|" onChange={() => {
                            if(document.getElementById("|11|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|11|">Sell Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|16|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|16|" onChange={() => {
                            if(document.getElementById("|16|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|16|">Move Stock</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|21|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|21|" onChange={() => {
                            if(document.getElementById("|21|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|21|">View Items History</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|59|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|59|" onChange={() => {
                            if(document.getElementById("|59|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|59|">View Compare Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|61|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|61|" onChange={() => {
                            if(document.getElementById("|61|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|61|">Bulk Trash Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|62|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|62|" onChange={() => {
                            if(document.getElementById("|62|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|62|">Direct Edit Items Prices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|65|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|65|" onChange={() => {
                            if(document.getElementById("|65|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|65|">Permanent Delete Trashed Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|66|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|66|" onChange={() => {
                            if(document.getElementById("|66|").checked) {
                                document.getElementById("|6|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|66|">Restore Trashed Items</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|12|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|12|" onChange={() => {
                            if(!document.getElementById("|12|").checked) {
                                if(document.getElementById("|13|")) {
                                    document.getElementById("|13|").checked = false;
                                }
                                if(document.getElementById("|14|")) {
                                    document.getElementById("|14|").checked = false;
                                }
                                if(document.getElementById("|15|")) {
                                    document.getElementById("|15|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|12|">View Categories</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|13|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|13|" onChange={() => {
                            if(document.getElementById("|13|").checked) {
                                document.getElementById("|12|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|13|">Add Categories</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|14|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|14|" onChange={() => {
                            if(document.getElementById("|14|").checked) {
                                document.getElementById("|12|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|14|">Remove Categories</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|15|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|15|" onChange={() => {
                            if(document.getElementById("|15|").checked) {
                                document.getElementById("|12|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|15|">Edit Categories</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|17|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|17|" onChange={() => {
                            if(!document.getElementById("|17|").checked) {
                                if(document.getElementById("|18|")) {
                                    document.getElementById("|18|").checked = false;
                                }
                                if(document.getElementById("|19|")) {
                                    document.getElementById("|19|").checked = false;
                                }
                                if(document.getElementById("|20|")) {
                                    document.getElementById("|20|").checked = false;
                                }
                                if(document.getElementById("|56|")) {
                                    document.getElementById("|56|").checked = false;
                                }
                                if(document.getElementById("|63|")) {
                                    document.getElementById("|63|").checked = false;
                                }
                                if(document.getElementById("|64|")) {
                                    document.getElementById("|64|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|17|">View Branches</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|18|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|18|" onChange={() => {
                            if(document.getElementById("|18|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|18|">Add Branch</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|19|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|19|" onChange={() => {
                            if(document.getElementById("|19|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|19|">Delete Branch</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|20|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|20|" onChange={() => {
                            if(document.getElementById("|20|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|20|">Edit Branch</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|63|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|63|" onChange={() => {
                            if(document.getElementById("|63|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|63|">View Branch Wallet</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|56|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|56|" onChange={() => {
                            if(document.getElementById("|56|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|56|">Update Branch Wallet</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|64|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|64|" onChange={() => {
                            if(document.getElementById("|64|").checked) {
                                document.getElementById("|17|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|64|">View Branch Wallet History</label>
                    </div>
                }
                

                {ok.data.access && ok.data.access.includes("|22|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|22|" onChange={() => {
                            if(!document.getElementById("|22|").checked) {
                                if(document.getElementById("|23|")) {
                                    document.getElementById("|23|").checked = false;
                                }
                                if(document.getElementById("|24|")) {
                                    document.getElementById("|24|").checked = false;
                                }
                                if(document.getElementById("|25|")) {
                                    document.getElementById("|25|").checked = false;
                                }
                                if(document.getElementById("|26|")) {
                                    document.getElementById("|26|").checked = false;
                                }
                                if(document.getElementById("|27|")) {
                                    document.getElementById("|27|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|22|">View Suppliers</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|23|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|23|" onChange={() => {
                            if(document.getElementById("|23|").checked) {
                                document.getElementById("|22|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|23|">Add Suppliers</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|24|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|24|" onChange={() => {
                            if(document.getElementById("|24|").checked) {
                                document.getElementById("|22|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|24|">Delete Suppliers</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|25|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|25|" onChange={() => {
                            if(document.getElementById("|25|").checked) {
                                document.getElementById("|22|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|25|">Edit Suppliers</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|26|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|26|" onChange={() => {
                            if(document.getElementById("|26|").checked) {
                                document.getElementById("|22|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|26|">Update Supplier Balance</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|27|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|27|" onChange={() => {
                            if(document.getElementById("|27|").checked) {
                                document.getElementById("|22|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|27|">View Suppliers Logs</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|28|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|28|" onChange={() => {
                            if(!document.getElementById("|28|").checked) {
                                if(document.getElementById("|29|")) {
                                    document.getElementById("|29|").checked = false;
                                }
                                if(document.getElementById("|30|")) {
                                    document.getElementById("|30|").checked = false;
                                }
                                if(document.getElementById("|31|")) {
                                    document.getElementById("|31|").checked = false;
                                }
                                if(document.getElementById("|37|")) {
                                    document.getElementById("|37|").checked = false;
                                }
                                if(document.getElementById("|38|")) {
                                    document.getElementById("|38|").checked = false;
                                }
                                if(document.getElementById("|57|")) {
                                    document.getElementById("|57|").checked = false;
                                }
                                if(document.getElementById("|58|")) {
                                    document.getElementById("|58|").checked = false;
                                }
                                if(document.getElementById("|67|")) {
                                    document.getElementById("|67|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|28|">View Cashboxes</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|29|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|29|" onChange={() => {
                            if(document.getElementById("|29|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|29|">Open/Close Cashbox</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|30|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|30|" onChange={() => {
                            if(document.getElementById("|30|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|30|">Update Cashbox Balance</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|31|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|31|" onChange={() => {
                            if(document.getElementById("|31|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|31|">View Cashbox Reports</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|37|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|37|" onChange={() => {
                            if(document.getElementById("|37|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|37|">Transfer Cashbox Amounts</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|38|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|38|" onChange={() => {
                            if(document.getElementById("|38|").checked) {
                                document.getElementById("|28|").checked = true;
                            } else {
                                if(document.getElementById("|67|")) {
                                    document.getElementById("|67|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|38|">In/Out Cashbox Amounts</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|67|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|67|" onChange={() => {
                            if(document.getElementById("|67|").checked) {
                                document.getElementById("|28|").checked = true;
                                document.getElementById("|38|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|67|">Out Cashbox to Expenses</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|57|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|57|" onChange={() => {
                            if(document.getElementById("|57|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|57|">Send Balance To Branches Wallets</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|58|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|58|" onChange={() => {
                            if(document.getElementById("|58|").checked) {
                                document.getElementById("|28|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|58|">Get Balance From Branches Wallets</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|32|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|32|" onChange={() => {
                            if(!document.getElementById("|32|").checked) {
                                if(document.getElementById("|33|")) {
                                    document.getElementById("|33|").checked = false;
                                }
                                if(document.getElementById("|34|")) {
                                    document.getElementById("|34|").checked = false;
                                }
                                if(document.getElementById("|35|")) {
                                    document.getElementById("|35|").checked = false;
                                }
                                if(document.getElementById("|36|")) {
                                    document.getElementById("|36|").checked = false;
                                }
                                if(document.getElementById("|39|")) {
                                    document.getElementById("|39|").checked = false;
                                }
                                if(document.getElementById("|44|")) {
                                    document.getElementById("|44|").checked = false;
                                }
                                if(document.getElementById("|55|")) {
                                    document.getElementById("|55|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|32|">View Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|33|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|33|" onChange={() => {
                            if(document.getElementById("|33|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|33|">View Invoices Items</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|34|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|34|" onChange={() => {
                            if(document.getElementById("|34|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|34|">Edit Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|35|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|35|" onChange={() => {
                            if(document.getElementById("|35|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|35|">Return Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|36|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|36|" onChange={() => {
                            if(document.getElementById("|36|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|36|">Delete Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|39|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|39|" onChange={() => {
                            if(document.getElementById("|39|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|39|">Pay Debt Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|44|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|44|" onChange={() => {
                            if(document.getElementById("|44|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|44|">Print Invoices</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|55|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|55|" onChange={() => {
                            if(document.getElementById("|55|").checked) {
                                document.getElementById("|32|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|55|">Update Invoice Final Price</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|40|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|40|" onChange={() => {
                            if(!document.getElementById("|40|").checked) {
                                if(document.getElementById("|41|")) {
                                    document.getElementById("|41|").checked = false;
                                }
                                if(document.getElementById("|42|")) {
                                    document.getElementById("|42|").checked = false;
                                }
                                if(document.getElementById("|43|")) {
                                    document.getElementById("|43|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|40|">View Money Operations</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|41|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|41|" onChange={() => {
                            if(document.getElementById("|41|").checked) {
                                document.getElementById("|40|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|41|">Add Money Operation</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|42|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|42|" onChange={() => {
                            if(document.getElementById("|42|").checked) {
                                document.getElementById("|40|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|42|">Update Money Operation</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|43|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|43|" onChange={() => {
                            if(document.getElementById("|43|").checked) {
                                document.getElementById("|40|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|43|">Delete Money Operation</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|45|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|45|" onChange={() => {
                            if(!document.getElementById("|45|").checked) {
                                if(document.getElementById("|46|")) {
                                    document.getElementById("|46|").checked = false;
                                }
                                if(document.getElementById("|60|")) {
                                    document.getElementById("|60|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|45|">View Staff Working Time System Login</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|46|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|46|" onChange={() => {
                            if(document.getElementById("|46|").checked) {
                                document.getElementById("|45|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|46|">Logout Staff from Working Time System</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|60|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|60|" onChange={() => {
                            if(document.getElementById("|60|").checked) {
                                document.getElementById("|45|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|60|">Update Staff Login System Dates</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|47|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|47|" />
                        <label class="queryAccess2 myLabelForCheckbox" for="|47|">View Reports</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|48|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|48|" onChange={() => {
                            if(!document.getElementById("|48|").checked) {
                                if(document.getElementById("|41|")) {
                                    document.getElementById("|41|").checked = false;
                                }
                                if(document.getElementById("|42|")) {
                                    document.getElementById("|42|").checked = false;
                                }
                                if(document.getElementById("|43|")) {
                                    document.getElementById("|43|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|48|">View Expenses</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|49|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|49|" onChange={() => {
                            if(document.getElementById("|49|").checked) {
                                document.getElementById("|48|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|49|">Add Expenses</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|50|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|50|" onChange={() => {
                            if(document.getElementById("|50|").checked) {
                                document.getElementById("|48|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|50|">Update Expenses</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|51|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|51|" onChange={() => {
                            if(document.getElementById("|51|").checked) {
                                document.getElementById("|48|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|51|">Delete Expenses</label>
                    </div>
                }
                
                {ok.data.access && ok.data.access.includes("|68|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|68|" onChange={() => {
                            if(!document.getElementById("|68|").checked) {
                                if(document.getElementById("|69|")) {
                                    document.getElementById("|69|").checked = false;
                                }
                                if(document.getElementById("|70|")) {
                                    document.getElementById("|70|").checked = false;
                                }
                                if(document.getElementById("|71|")) {
                                    document.getElementById("|71|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|68|">View Serials Codes</label>
                    </div>
                }
                {/*
                {ok.data.access && ok.data.access.includes("|69|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|69|" onChange={() => {
                            if(document.getElementById("|69|").checked) {
                                document.getElementById("|68|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|69|">Add Serials Codes</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|70|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|70|" onChange={() => {
                            if(document.getElementById("|70|").checked) {
                                document.getElementById("|68|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|70|">Update Serials Codes</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|71|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|71|" onChange={() => {
                            if(document.getElementById("|71|").checked) {
                                document.getElementById("|68|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|71|">Delete Serials Codes</label>
                    </div>
                }
                */}
                
                {ok.data.access && ok.data.access.includes("|72|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|72|" onChange={() => {
                            if(!document.getElementById("|72|").checked) {
                                if(document.getElementById("|73|")) {
                                    document.getElementById("|73|").checked = false;
                                }
                                if(document.getElementById("|74|")) {
                                    document.getElementById("|74|").checked = false;
                                }
                                if(document.getElementById("|75|")) {
                                    document.getElementById("|75|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|72|">View Service Page</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|73|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|73|" onChange={() => {
                            if(document.getElementById("|73|").checked) {
                                document.getElementById("|72|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|73|">Add Service Item</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|74|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|74|" onChange={() => {
                            if(document.getElementById("|74|").checked) {
                                document.getElementById("|72|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|74|">Delete Service Item</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|75|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|75|" onChange={() => {
                            if(document.getElementById("|75|").checked) {
                                document.getElementById("|72|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|75|">Update Service Item</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|76|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|76|" onChange={() => {
                            if(document.getElementById("|76|").checked) {
                                document.getElementById("|72|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|76|">Fix Service Item</label>
                    </div>
                }
                
                {ok.data.access && ok.data.access.includes("|77|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|77|" onChange={() => {
                            if(!document.getElementById("|77|").checked) {
                                if(document.getElementById("|79|")) {
                                    document.getElementById("|79|").checked = false;
                                }
                                if(document.getElementById("|80|")) {
                                    document.getElementById("|80|").checked = false;
                                }
                                if(document.getElementById("|78|")) {
                                    document.getElementById("|78|").checked = false;
                                }
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|77|">View Auth Data Page</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|79|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|79|" onChange={() => {
                            if(document.getElementById("|79|").checked) {
                                document.getElementById("|77|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|79|">Add Auth Data Entry</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|80|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|80|" onChange={() => {
                            if(document.getElementById("|80|").checked) {
                                document.getElementById("|77|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|80|">Delete Auth Data Entry</label>
                    </div>
                }
                {ok.data.access && ok.data.access.includes("|78|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|78|" onChange={() => {
                            if(document.getElementById("|78|").checked) {
                                document.getElementById("|77|").checked = true;
                            }
                        }} />
                        <label class="queryAccess2 myLabelForCheckbox" for="|78|">Update Auth Data Entry</label>
                    </div>
                }

                {ok.data.access && ok.data.access.includes("|52|") &&
                    <div class="flex items-center mr-auto" style={{marginBottom: "1%"}}>
                        <input type="checkbox" class="queryAccess myCheckboxStyle" id="|52|" />
                        <label class="queryAccess2 myLabelForCheckbox" for="|52|">View History</label>
                    </div>
                }
                </>
            ]});
        })
    }

    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    setupClients = async() => {
        
    }

    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        await axios.post("/get_suppliers").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({clientsList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({clientsList:data});
            }
        })
        window.toastr.remove();
    }

    addAdmin = () => {
        const username = $("#usernameInput").val();
        const password = $("#passwordInput").val();
        const type = $("#adminType").val();
        const accessData = document.querySelectorAll(".queryAccess");
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();
        const linkedClient = $("#linkedClientVal").val();

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        if(username.length < 3) {
            window.toastr["error"]("Invalid Username");
            return;
        }
        if(password.length < 3) {
            window.toastr["error"]("Invalid Password");
            return;
        }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }
        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }

        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/add_admin", {linkedClient:linkedClient, username:username, password:password, access:accessRes, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteAdmin = () => {
        axios.post("/del_admin", {id:this.state.adminIdToDel}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    processDays = () => {
        const days = document.querySelectorAll(".queryAccessDays");
        var selectedDays = "";
        var count = 0;
        for(var i = 0; i < days.length; ++i) {
            const isChecked = days[i].checked;
            if(isChecked) {
                selectedDays += days[i].id+", ";
                count += 1;
            }
        }
        // $("#offDaysIn").val(count);
        selectedDays = selectedDays.slice(0, -2);
        if(selectedDays) {
            $("#selectDaysBtn").text(selectedDays);
        } else {
            $("#selectDaysBtn").text("No OFF");
        }
        // console.log(selectedDays);
    }

    processTimeSum = () => {
        const data = $("#workHoursFromToIn").val();
        // console.log("["+data+"]");
        if(data && (data.match(/.*AM --- .*PM/) || data.match(/.*AM --- .*AM/) || data.match(/.*PM --- .*PM/) || data.match(/.*PM --- .*AM/))) {
            var firstNum = data.split(" --- ")[0].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            var secondNum = data.split(" --- ")[1].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            const firstPart = data.split(" --- ")[0];
            const secondPart = data.split(" --- ")[1];
            var numOfHours = "0";

            if(firstPart.includes("PM")) {
                if(!firstPart.includes("12")) {
                    firstNum = String(parseFloat(firstNum) + 12);
                }
            }
            if(secondPart.includes("PM")) {
                if(!secondPart.includes("12")) {
                    secondNum = String(parseFloat(secondNum) + 12);
                }
            }
            if(firstPart.includes("12AM")) {
                firstNum = "00";
            }
            if(secondPart.includes("12AM")) {
                secondNum = "00";
            }
            const timeStart = new Date("01/01/2007 " + firstNum+":00:00").getHours();
            const timeEnd = new Date("01/01/2007 " + secondNum+":00:00").getHours();
            numOfHours = timeEnd - timeStart;
            if (numOfHours < 0) {
                numOfHours = 24 + numOfHours;
            }
            // console.log("{"+numOfHours+"}");
            $("#workHoursIn").val(numOfHours);
            // console.log("--"+$("#workHoursIn").val()+"--");
        } else {
            window.toastr["error"]("Invalid Time Syntax, Accepted only (*AM --- *PM)");
            return false;
        }
        return true;
    }

    editAdmin = () => {
        const type = $("#adminType").val();
        if(type === "employee") {
            // this.processDays();
            if(!this.processTimeSum()) {
                return;
            }
        }
        const password = $("#passwordInput").val();
        const accessData = document.querySelectorAll(".queryAccess")
        const status = $("#adminUpdateStatus").val();
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();
        const linkedClient = $("#linkedClientVal").val();
        const newUserName = $("#newEmailSetEdit").val();
        // console.log("("+workHours+")");

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        // if(password.length < 3) {
        //     window.toastr["error"]("Invalid Password");
        //     return;
        // }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }

        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }
        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/edit_admin", {newUserName:newUserName, linkedClient:linkedClient, id:this.state.adminIdToDel, password:password, access:accessRes, status:status, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    // updateStaffBalance = (type) => {
    //     const name = this.state.adminToDel;
    //     const id = this.state.adminIdToDel;
    //     const balance = $("#balanceToChangeIn").val();
    //     const note = $("#balanceChangeNote").val();
    //     if(!balance || parseFloat(balance) < 0) {
    //         window.toastr["error"]("Invalid Balance Value");
    //         return;
    //     }
    //     if(!note) {
    //         window.toastr["error"]("Invalid Note Value");
    //         return;
    //     }
    //     var api = "/add_staff_balance";
    //     if(type === "-") {
    //         api = "/remove_staff_balance";
    //     }
    //     axios.post(api, {name:name, id:id, balance:balance, note:note}).then((ok)=>{
    //         window.toastr[ok.data.status](ok.data.msg);
    //         if(ok.data.status === "success") {
    //             window.$("#updateBalanceModal").modal("hide");
    //             window.$('#table1').DataTable().ajax.reload(null, false);
    //         }
    //     })
    // }
    
    setupEmployeeData = () => {
        const staff = $("#boxForSupplierId").val();
        const date = $("#selectedDate").val();
        if(staff && date) {
            axios.post("/get_final_staff_data", {staff:staff, date:date}).then((ok)=>{
                if(ok.data.status === "success") {
                    $("#salaryInput").html(ok.data.salary);
                    $("#earnsInput").html(ok.data.earns);
                    $("#passedDaysInput").html(ok.data.count);
                    $("#linkedClientInput").html(ok.data.linkedClient);
                    const linkedClientBalance = ok.data.linkedClientBalance;
                    $("#linkedClientBalanceInput").html(ok.data.linkedClientBalance);
                    this.setState({linkedClientId:ok.data.linkedClientId});
                    // if(!isNaN(linkedClientBalance)) {
                    //     console.log("Exists ! ["+ok.data.linkedClientId+"]");
                    // }
                    $("#finalEmployeeData").show();
                } else if(ok.data.status === "error") {
                    // console.log(ok.data.msg);
                    $("#finalEmployeeData").hide();
                }
            });
        } else {
            $("#finalEmployeeData").hide();
        }
    }
    
    getMonthDate = (monthNum) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() + monthNum);
        const fYear = date.getFullYear();
        const mMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const monthDate = fYear+"-"+mMonth;
        return monthDate;
    }
    getYearDate = (yearNum) => {
        const date = new Date();
        date.setDate(1);
        date.setFullYear(date.getFullYear() + yearNum);
        const fYear = date.getFullYear();
        return fYear;
    }
    logoutStaff = () => {
        const id = this.state.adminIdToDel;
        axios.post("/admin_logout_staff", {id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#logoutStaffModal").modal("hide");
                window.$('#table2').DataTable().ajax.reload(null, false);
            }
        });
    }
    updateStaffDates = () => {
        const entryId = this.state.adminIdToDel;
        var loginDate = $("#inStaffLoginDate").val();
        var logoutDate = $("#inStaffLogoutDate").val();
        if(!loginDate.includes(", ")) {
            window.toastr["error"]("Invalid Login Date");
            return;
        }
        if(!logoutDate.includes(", ")) {
            window.toastr["error"]("Invalid Logout Date");
            return;
        }
        loginDate = loginDate.split(",")[0].split("/")[2]+"-"+loginDate.split(",")[0].split("/")[1]+"-"+loginDate.split(",")[0].split("/")[0]+","+loginDate.split(",")[1];
        logoutDate = logoutDate.split(",")[0].split("/")[2]+"-"+logoutDate.split(",")[0].split("/")[1]+"-"+logoutDate.split(",")[0].split("/")[0]+","+logoutDate.split(",")[1];
        
        // console.log(loginDate, logoutDate);
        // return;
        
        axios.post("/update_staff_dates", {id:entryId, loginDate:loginDate, logoutDate:logoutDate}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateDatesModal").modal("hide");
                window.$('#table2').DataTable().ajax.reload(null, false);
            }
        });
    }
    
    updateSupplierBalance = (type) => {
        const name = this.state.adminToDel2;
        const id = this.state.adminIdToDel2;
        const balance = $("#balanceToChangeIn").val();
        const note = $("#balanceChangeNote").val();
        if(!balance || parseFloat(balance) < 0) {
            window.toastr["error"]("Invalid Balance Value");
            return;
        }
        if(!note) {
            window.toastr["error"]("Invalid Note Value");
            return;
        }
        var api = "/add_supplier_balance";
        if(type === "-") {
            api = "/remove_supplier_balance";
        }
        axios.post(api, {name:name, id:id, balance:balance, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateBalanceModal").modal("hide");
                window.$('#table2').DataTable().ajax.reload(null, false);
            }
        })
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="updateBalanceModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td id="setSupplierName"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td id="setSupplierBalance"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance to change:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceToChangeIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="balanceChangeNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateSupplierBalance("-");
                            }}>Remove Balance</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateSupplierBalance("+");
                            }}>Add Balance</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="updateDatesModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Staff Entry ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff Name:</th>
                                        <td>{this.state.adminToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff Working Hours:</th>
                                        <td id="inStaffWorkingHours"></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Passed Hours:</th>
                                        <td id="inStaffPassedHours">></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Login Date:</th>
                                        <td><input class="myInputStyle" id="inStaffLoginDate" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Staff Logout Date:</th>
                                        <td><input class="myInputStyle" id="inStaffLogoutDate" placeholder=""/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='moveItemStockBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateStaffDates();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="logoutStaffModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Logging Out Staff
                        </div>
                        <div class="modal-body">
                            Are you sure you want to Force Logout ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.logoutStaff();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#logoutStaffModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/*<div class="modal fade" id="updateBalanceModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td id="setStaffName"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td id="setStaffBalance"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance to change:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceToChangeIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="balanceChangeNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateStaffBalance("-");
                            }}>Remove Balance</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateStaffBalance("+");
                            }}>Add Balance</button>
                        </div>
                    </div>
                </div>
            </div>*/}
            
            <div class="modal fade" id="suppliersLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Working Time Log of Staff ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <input id="boxForSupplierId" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                                <tbody>
                                    <tr>
                                        <th>Date:</th>
                                        <td>
                                            <select id="selectedDate" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                                window.$('#table2').DataTable().ajax.reload(null, false);
                                                // this.setupEmployeeData();
                                            }}>
                                                <option value="">All</option>
                                                <option value={this.getMonthDate(0)}>This Month {this.getMonthDate(0)}</option>
                                                <option value={this.getMonthDate(-1)}>Previous Month {this.getMonthDate(-1)}</option>
                                                <option value={this.getMonthDate(-2)}>Previous Month {this.getMonthDate(-2)}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Staff ID</th>
                                        <th>Staff Name</th>
                                        <th>Login Date</th>
                                        <th>Status</th>
                                        <th>Logout Date</th>
                                        <th>Hours Passed</th>
                                        <th>Salary</th>
                                        <th>Working Hours</th>
                                        <th>Branch</th>
                                        <th>OFF Days</th>
                                        <th>Earns</th>
                                        <th>Login Note</th>
                                        <th>Logout Note</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-modal" id="finalEmployeeData" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                                <tbody>
                                    <tr>
                                        <th>Salary:</th>
                                        <td id="salaryInput"></td>
                                    </tr>
                                    <tr>
                                        <th>Passed Days:</th>
                                        <td id="passedDaysInput"></td>
                                    </tr>
                                    <tr>
                                        <th>Linked Client:</th>
                                        <td id="linkedClientInput"></td>
                                    </tr>
                                    <tr>
                                        <th>Linked Client Balance:</th>
                                        <td id="linkedClientBalanceInput"></td>
                                        {this.state.user.access && this.state.user.access.includes("|26|") && !isNaN($("#linkedClientBalanceInput").html()) && <td>
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                                const itemId = this.state.linkedClientId;
                                                const itemName = $("#linkedClientInput").html();
                                                const itemBalance = $("#linkedClientBalanceInput").html();
                                                this.setState({adminToDel2:itemName, adminIdToDel2:itemId});
                                                $("#setSupplierName").html(itemName);
                                                $("#setSupplierBalance").html(itemBalance);
                                                
                                                window.$("#updateBalanceModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#updateBalanceModal").on("shown.bs.modal", function () {
                                                    $("#suppliersLogsHistory").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#suppliersLogsHistory").css({zIndex: "1050", "overflow-y": "auto"});
                                                });
                                                
                                                window.$("#updateBalanceModal").modal("show");
                                            }}>Update Balance</button>
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>Earns:</th>
                                        <td id="earnsInput"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Admin
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove ({this.state.adminTypeToDel}) ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteAdmin();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteAdminModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr id="emailSetView">
                                        <th>Username:</th>
                                        <td id="emailSetEdit"></td>
                                    </tr>
                                    <tr id="emailEditView">
                                        <th>New Username:</th>
                                        <td><input class="myInputStyle" id="newEmailSetEdit"/></td>
                                    </tr>
                                    <tr id="emailInputView">
                                        <th>Username:</th>
                                        <td><input class="myInputStyle" id="usernameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Password:</th>
                                        <td><input class="myInputStyle" id="passwordInput" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Access:</th>
                                        <td style={{display: "block", overflowY: "scroll", height: "400px", border: "groove"}}>
                                            <input class="myInputStyle" id="accessSearch" placeholder="Search for Access..." style={{margin: "3px"}} onChange={() => {
                                                const data = document.querySelectorAll(".queryAccess2");
                                                for (var i = 0; i < data.length; ++i) {
                                                    if($(data[i]).html().toLocaleLowerCase().includes($("#accessSearch").val().toLocaleLowerCase())) {
                                                        $(data[i]).parent().show();
                                                    } else {
                                                        $(data[i]).parent().hide();
                                                    }
                                                }
                                            }} />
                                            {this.state.perms}
                                        </td>
                                    </tr>
                                    <tr id="statusView">
                                        <th>Status:</th>
                                        <td><select id="adminUpdateStatus" className="myInputStyle">
                                                <option value="Active">Active</option>
                                                <option value="Blocked">Blocked</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Linked Client:</th>
                                        <td><select id="linkedClientVal" className="myInputStyle">
                                                <option value="">No Client</option>
                                                {this.state.clientsList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr id="typeView">
                                        <th>Type:</th>
                                        <td>
                                            <select id="adminType" className="myInputStyle" onChange={() => {
                                                if($("#adminType").val() === "employee") {
                                                    document.getElementById("|1|").checked = true;
                                                    document.getElementById("|6|").checked = true;
                                                    document.getElementById("|11|").checked = true;
                                                    document.getElementById("|12|").checked = true;
                                                    document.getElementById("|13|").checked = true;
                                                    document.getElementById("|68|").checked = true;
                                                    document.getElementById("|28|").checked = true;
                                                    document.getElementById("|29|").checked = true;
                                                    document.getElementById("|31|").checked = true;
                                                    document.getElementById("|37|").checked = true;
                                                    document.getElementById("|38|").checked = true;
                                                    document.getElementById("|32|").checked = true;
                                                    document.getElementById("|33|").checked = true;
                                                    document.getElementById("|34|").checked = true;
                                                    document.getElementById("|35|").checked = true;
                                                    document.getElementById("|39|").checked = true;
                                                    document.getElementById("|44|").checked = true;
                                                    document.getElementById("|23|").checked = true;
                                                    document.getElementById("|22|").checked = true;
                                                    document.getElementById("|26|").checked = true;
                                                    document.getElementById("|27|").checked = true;
                                                    document.getElementById("|59|").checked = true;
                                                    $("#selectDaysBtn").text("No OFF");
                                                    $(".employeeOptions").show();
                                                } else {
                                                    $(".employeeOptions").hide();
                                                }
                                            }}>
                                                <option value="admin">Admin</option>
                                                <option value="employee">Employee</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className="employeeOptions">
                                        <th>Branch:</th>
                                        <td>
                                            <select id="branchIn" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className="employeeOptions">
                                        <th>Salary:</th>
                                        <td><input class="myInputStyle" id="salaryIn" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr style={{display: "none"}}>
                                        <th>Working Hours (Number):</th>
                                        <td><input class="myInputStyle" id="workHoursIn" type="number" placeholder="Example: 10" readOnly/></td>
                                    </tr>
                                    <tr className="employeeOptions">
                                        <th>Working Hours (From/To):</th>
                                        <td><input class="myInputStyle" id="workHoursFromToIn" placeholder="Example: 10AM --- 10PM" onBlur={() => {
                                            this.processTimeSum();
                                        }}/></td>
                                    </tr>
                                    <tr className="employeeOptions">
                                        <th>OFF Days Number:</th>
                                        <td><input class="myInputStyle" id="offDaysIn" type="number" placeholder="Example: 1" /></td>
                                    </tr>
                                    <tr className="employeeOptions" style={{display: "none"}}>
                                        <th>OFF Days:</th>
                                        <td>
                                            <div class='dropdown' style={{width: "100%"}}>
                                                <button class='dropbtn myBtnStyle2 myDropdownBtnStyle' id="selectDaysBtn" style={{width: "100%"}}>No OFF</button>
                                                <div class='dropdown-content-multi'>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Saturday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Saturday">Saturday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Sunday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Sunday">Sunday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Monday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Monday">Monday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Tuesday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Tuesday">Tuesday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Wednesday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Wednesday">Wednesday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Thursday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Thursday">Thursday</label>
                                                    <br/>
                                                    <input type="checkbox" class="queryAccessDays myCheckboxStyle" id="Friday" onChange={() => { this.processDays() }} />
                                                    <label class="queryAccessDays2 myLabelForCheckbox" for="Friday">Friday</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addAdmin();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.editAdmin();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Staff Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>

                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Type:</th>
                            <td>
                                <select id="selectedType" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    <option value="admin">Admins</option>
                                    <option value="employee">Employees</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Salary</th>
                            <th>Work Hours Sum</th>
                            <th>Work Hours Range</th>
                            <th>OFF Days Num</th>
                            <th>OFF Days</th>
                            <th>Branch</th>
                            <th>Linked Client</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
