import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: ""
    }

    setupDatatable() {
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        const self = this;
        window.$('#table1').DataTable({
            'columnDefs': [{
            'targets': [1],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                await axios.post('/auth_data_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const branch = resData[vCount].branch;
                    const ip = resData[vCount].ip;
                    const fingerPrint = resData[vCount].browser_fingerprint;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    if(self.state.user.access.includes("|80|")) {
                        buttons += "<a class='myAStyle' id='delete_branch' itemId='"+id+"' itemName='"+branch+"' style='color: red !important;'>Delete</a>";
                    }
                    if(self.state.user.access.includes("|78|")) {
                        buttons += "<a class='myAStyle' id='edit_branch' itemId='"+id+"' branch='"+branch+"' ip='"+ip+"' fingerprint='"+fingerPrint+"'>Update</a>";
                    }

                    buttons += "</div></div>";
                    out.push([id, branch, ip, fingerPrint, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#delete_branch");
        $(document).on("click", "#delete_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteBranchModal").modal("show");
        });

        $(document).off("click", "#edit_branch");
        $(document).on("click", "#edit_branch", function() {
            const itemId = $(this).attr("itemId");
            const branch = $(this).attr("branch");
            const fingerprint = $(this).attr("fingerprint");
            const ip = $(this).attr("ip");

            self.setState({adminIdToDel:itemId});
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();

            $("#branchIn1").val(branch);
            $("#ipIn1").val(ip);
            $("#fingerprintIn1").val(fingerprint);
            window.$("#addAuthDataEntry").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|17|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#auth_data_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            this.setupBranches();

            if(ok.data.access.includes("|18|")) {
                this.setState({addAdminsBtn:[
                    <>
                        <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                            $("#addAdminBtn").show();
                            $("#editAdminBtn").hide();
                            window.$("#addAuthDataEntry").modal("show");
                        }}>+ Add</button>
                    </>
                ]})
            }
        })
    }
    
    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        window.toastr.remove();
    }

    addAuthEntry = () => {
        const branch = $("#branchIn1").val();
        const ip = $("#ipIn1").val();
        const fingerPrint = $("#fingerprintIn1").val();
        
        if(!branch) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        if(!ip && !fingerPrint) {
            window.toastr["error"]("Must Insert IP or Login Device Fingerprint");
            return;
        }
        axios.post("/add_auth_data_entry", {ip:ip, fingerprint:fingerPrint, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAuthDataEntry").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }
    
    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    deleteAuthDataEntry = () => {
        const id = this.state.adminIdToDel;
        axios.post("/delete_auth_data_entry", {id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    updateAuthEntry = () => {
        const id = this.state.adminIdToDel;
        const branch = $("#branchIn1").val();
        const ip = $("#ipIn1").val();
        const fingerPrint = $("#fingerprintIn1").val();
        
        if(!branch) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        if(!id) {
            window.toastr["error"]("Invalid ID");
            return;
        }
        if(!ip && !fingerPrint) {
            window.toastr["error"]("Must Insert IP or Login Device Fingerprint");
            return;
        }
        axios.post("/update_auth_data_entry", {id:id, ip:ip, fingerprint:fingerPrint, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAuthDataEntry").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Auth Data Entry
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Auth Data Entry ({this.state.adminIdToDel}) for Branch ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteAuthDataEntry();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addAuthDataEntry" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="branchIn1" className="myInputStyle" style={{width: "fit-content"}}>
                                                <option value="">All</option>
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>IP:</th>
                                        <td><input class="myInputStyle" id="ipIn1"/></td>
                                    </tr>
                                    <tr>
                                        <th>Login Device Fingerprint:</th>
                                        <td><input class="myInputStyle" id="fingerprintIn1"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addAuthEntry();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateAuthEntry();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Auth Data Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Branch</th>
                            <th>IP</th>
                            <th>Login Device Fingerprint</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
{/*
            <table id="table1" className="MuiTable-root MuiTable-stickyHeader min-w-xl muiltr-5g2394-MuiTable-root" aria-labelledby="tableTitle">
                <thead className="MuiTableHead-root muiltr-15wwp11-MuiTableHead-root">
                    <tr className="MuiTableRow-root MuiTableRow-head h-48 sm:h-64 muiltr-1yv0chr-MuiTableRow-root">
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            ID
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Username
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Access
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Status
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="MuiTableBody-root muiltr-apqrd9-MuiTableBody-root">
                    <tr className="MuiTableRow-root MuiTableRow-hover h-72 cursor-pointer muiltr-1yv0chr-MuiTableRow-root" role="checkbox" aria-checked="false" tabIndex={-1}>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                    </tr>
                </tbody>
            </table>
*/}
            </>
        )
    }
}

export default main;
